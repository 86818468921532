import ApiRequest from '../utilities/ApiRequest';
import {ERROR_ARR} from "../config/error";

class Cms {

    /**
     * 公告列表
     *
     * @param that
     * @param then
     */
    static newsList(that, then) {
        ApiRequest.get('api/cms/news', {params: that.params})
            .then(data => {

                // 失败
                if (data.code) {
                    that.$Message.warning(ERROR_ARR[that.$i18n.locale][data.code] || data.msg);
                    return;
                }

                // 成功
                then(data.data);
            })
            .catch(() => {
            });
    }

    /**
     * 公告详情
     * @param that
     * @param then
     */
    static newsDetail(that, then) {
        ApiRequest.get('api/cms/news/show', {params: that.params})
            .then(data => {

                // 失败
                if (data.code) {
                    that.$Message.warning(ERROR_ARR[that.$i18n.locale][data.code] || data.msg);
                    return;
                }

                // 成功
                then(data.data);
            })
            .catch(() => {
            });
    }

    /**
     * version
     * @param that
     * @param then
     */
    static version(that, then) {
        ApiRequest.get('api/appInfo')
            .then(data => {

                // 失败
                if (data.code) {
                    that.$Message.warning(ERROR_ARR[that.$i18n.locale][data.code] || data.msg);
                    return;
                }

                // 成功
                then(data.data);
            })
            .catch(() => {
            });
    }
}

export default Cms;
